<template>
    <b-alert v-if="shouldShowBanner" :show="true" variant="warning" class="mb-0 text-center">
      <div class="container" ref="maintenance-message">
        You are currently visiting mini-my. Tuesdays 07:00 - 08:15 (Warwick Time) is the my.wbs at risk period
        when we perform essential updates and maintenance. Whilst my.wbs is unavailable and to ensure a smooth
        experience, users are automatically redirected to mini-my during this time.
      </div>
    </b-alert>
</template>

<script>
import { timeService} from "@/services/time.service";

export default {
  name: 'MaintenanceAlert',

  inject: {
    timeService: {
      default: () => timeService
    }
  },

  data() {
    return {
      maintenanceStart: { hour: 7, minute: 0 },
      maintenanceEnd: { hour: 8, minute: 15 }
    }
  },

  computed: {
    shouldShowBanner() {
      const isVisible = this.isMaintenanceWindow();
      console.log('isVisible', isVisible);
      this.$store.dispatch('appState/setMaintenanceMode', isVisible);
      return isVisible
    }
  },

  methods: {
    isMaintenanceWindow() {
      const dateTimeToLocale = this.timeService.getCurrentTime();
      const [date, time] = dateTimeToLocale.split(', ');
      const [day, month, year] = date.split('/');
      const currentDateTime = new Date(`${year}-${month}-${day} ${time}`);

      if (currentDateTime.getDay() !== 2) return false;

      const startTime = new Date(`${year}-${month}-${day} ${time}`);
      startTime.setHours(this.maintenanceStart.hour, this.maintenanceStart.minute, 0);

      const endTime = new Date(`${year}-${month}-${day} ${time}`);
      endTime.setHours(this.maintenanceEnd.hour, this.maintenanceEnd.minute, 0);

      return currentDateTime >= startTime && currentDateTime <= endTime;
    }
  },
  mounted() {
    this.timer = setInterval(() => {
      this.$forceUpdate();
    }, 60000);
  },

  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }
}
</script>