import http from "./http.service";
import fileDownload from "js-file-download";

function fetchAttachment(entry, attachmentIndex, responseType = 'blob') {

    function attachmentUrl(entry, attachmentIndex) {

        const attachment = entry.fileAttachments.find(a => a.index === attachmentIndex);

        return `/api/files/${entry.type}/${entry.identifier}/${attachment.version}/${attachment.number}`

    }

    return http.get(attachmentUrl(entry, attachmentIndex), { responseType })

}

function downloadFile(filename, attachmentIndex, toaster) {

    fetchAttachment(this.entry, attachmentIndex)
        .then(r => fileDownload(r.data, filename))
        .catch(() => {
            toaster.toast("There was an error downloading the file", {
                title: "Error downloading file"
            });
        })

}

export { fetchAttachment, downloadFile }