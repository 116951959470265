<template>
    <div id="app">
        <header class="sticky-top">
          <a class="sr-only sr-only-focusable" href="#main-content">Skip to main content</a>

          <MaintenanceAlert/>

          <template v-if="showNavBar">
            <b-alert :show="isImpersonating" variant="danger" class="mb-0 text-center">
                Impersonating: <strong>{{currentUser.name}}</strong>.
                <b-button variant="danger" size="sm" @click="onResetImpersonation">End impersonation</b-button>
            </b-alert>

            <b-alert :show="myWbsIsDown && !isMaintenanceMode" variant="warning" class="mb-0 text-center">
                You have arrived here because
                <strong>my.wbs is currently unavailable</strong>.
                View our <b-link class="alert-link" href="https://status.wbs.ac.uk" size="sm">status page</b-link> for more information.
            </b-alert>

            <b-alert :show="myWbsUnderHeavyLoad && !isMaintenanceMode" variant="warning" class="mb-0 text-center">
                You have arrived here because
                <strong>my.wbs is currently under heavy load</strong>.
                <i>{{getTryMyWbsAgainMessage}}</i>
                <span v-if="canGoToMyWbs"><br>
              If it's essential, you can return to my.wbs using this
              <b-link class="alert-link" to="/goToMyWbs" size="sm">priority link</b-link>.
            </span>
            </b-alert>

            <b-navbar toggleable="md" type="light" variant="light" class="border shadow-sm">

                <b-navbar-brand to="/"><h1>mini-my.wbs</h1></b-navbar-brand>              
                <SystemStatusIndicator />
                <div class="d-flex flex-row order-2 ml-auto">

                    <b-navbar-nav class="flex-row">

                        <nav-button
                            modal="emergencies"
                            tool-tip="Emergencies & Wellbeing"
                            variant="success"
                            :icon="['fas', 'medkit']"/>

                        <nav-button
                            modal="help"
                            tool-tip="Help"
                            variant="outline-info"
                            :icon="['fas', 'question']"/>

                        <nav-button
                            v-if="currentUser.canImpersonate"
                            modal="impersonate-user"
                            tool-tip="Impersonate another user"
                            variant="outline-warning"
                            :icon="['fas', 'user-secret']"/>

                    </b-navbar-nav>

                    <b-navbar-toggle target="nav-collapse" class="ml-1 ml-md-0"></b-navbar-toggle>

                </div>

                <b-collapse id="nav-collapse" is-nav class="ml-4 order-3">
                    <b-navbar-nav class="ml-auto">
                        <b-nav-text class="mr-2">
                            <font-awesome-icon :icon="['fas', 'user']" class="mr-1"/>
                            {{ currentUser.name }}
                        </b-nav-text>

                        <b-nav-item @click.prevent="handleSignOut" right>
                            <font-awesome-icon :icon="['fas', 'sign-out-alt']"/>
                            Sign out
                        </b-nav-item>
                    </b-navbar-nav>
                </b-collapse>
            </b-navbar>
          </template>
        </header>

        <main id="main-content" class="container">
            <router-view/>
        </main>

        <ImpersonationForm/>
        <HelpPage/>
        <EmergencyContacts/>
        <SignOutModal :log-out="logOut"/>
    </div>
</template>

<script>

import ImpersonationForm from './components/ImpersonationForm';
import HelpPage from './components/HelpPage';
import EmergencyContacts from './components/EmergencyContacts';
import SignOutModal from './components/SignOutModal.vue';
import httpService from "./services/http.service";
import humanizeDuration from "humanize-duration";
import NavButton from "@/NavButton";
import SystemStatusIndicator from "@/views/SystemStatus";
import MaintenanceAlert from "@/components/MaintenanceAlert.vue";

export default {
        data() {
            const wbsConStatExpiryCookieDurationMins = 15;
            return {
                statusInterval: null,
                tryMyWbsAgainMinsToGo : wbsConStatExpiryCookieDurationMins,
                tryMyWbsAgainTime: this.$moment().add(wbsConStatExpiryCookieDurationMins, "minutes"),
                wbsConStatExpiryCookieDurationMins
            };
        },
        components: {MaintenanceAlert, NavButton, ImpersonationForm, HelpPage, EmergencyContacts, SignOutModal, SystemStatusIndicator },
        computed: {
            showNavBar() {
              return this.currentUser && this.$store.getters["auth/isFullyAuthenticated"];
            },
            currentUser() {
                return this.$store.getters["auth/isLoggedIn"]
                    ? this.$store.getters["auth/user"]
                    : null;
            },
            isImpersonating() {
                return this.$store.getters["auth/isImpersonating"];
            },
            canGoToMyWbs() {
                return this.$store.getters["auth/canGoToMyWbs"];
            },
            myWbsUnderHeavyLoad() {
                return this.$store.getters["appState/isMyWbsUnderHeavyLoad"];
            },
            myWbsIsDown() {
              return this.$store.getters["appState/isMyWbsDown"];
            },
            getTryMyWbsAgainMessage() {
                return this.tryMyWbsAgainTime.isAfter(this.$moment())
                    ? `You can try again in
                       ${humanizeDuration(Math.ceil(this.tryMyWbsAgainMinsToGo) * 60000)}.`
                    : `You can try again now.`;
            },
            isMaintenanceMode() {
              return this.$store.getters['appState/isMaintenanceMode'];
            }
        },
        methods: {
            handleSignOut() {
              if (this.$store.getters["auth/ssoId"]) {
                this.$bvModal.show('signOutOptions');
              } else {
                this.logOut();
              }
            },
            logOut() {
                this.$store
                    .dispatch("auth/logout")
                    .then(() => this.$router.push("/login?freshLogin=true"));
            },
            setMyWbsConnectionStatus() {

                const updateStatus = async () => {
                    await this.$store.dispatch(
                        "appState/setMyWbsConnectionStatus",
                        this.$cookies.get("wbs_con_stat")
                    );
                }
                updateStatus();

                if (this.$store.getters["appState/isMyWbsUnderHeavyLoad"]) {
                    this.updateTryMyWbsAgainMinsToGo();
                } else {
                    clearInterval(this.statusInterval);
                }
            },
            updateTryMyWbsAgainMinsToGo() {
                this.tryMyWbsAgainMinsToGo
                    = this.$moment.duration(this.tryMyWbsAgainTime.diff(this.$moment())).asMinutes()
            },
            onResetImpersonation() {
                const resetImpersonation = async () => {
                    await this.$store.dispatch("auth/resetImpersonation");
                    this.$router.go(0)
                };
                resetImpersonation()
            }
        },
        beforeCreate() {
            const token = this.$store.getters["auth/token"];
            httpService.setAuthorizationHeader(token);
        },
        async mounted() {

            //this.$cookies.set("wbs_con_stat", "redirect", 900); //15 min expiry

            this.setMyWbsConnectionStatus();

            this.statusInterval = setInterval(this.setMyWbsConnectionStatus, 10000);

        }
    };
</script>

<style scoped>
    #nav-collapse {
        flex-grow: 0;
    }
</style>
