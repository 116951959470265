const initialState = {
  myWbsConnectionStatus: null,
  isMaintenanceMode: false
};

export const appState = {
  namespaced: true,
  state: initialState,
  getters: {
    isMyWbsUnderHeavyLoad: state => {
      return state.myWbsConnectionStatus === 'redirect';
    },
    isMyWbsDown: state => {
      return state.myWbsConnectionStatus === 'down';
    },
    isMaintenanceMode: state => state.isMaintenanceMode
  },
  actions: {
    setMyWbsConnectionStatus({ commit }, connectionStatus) {
      commit('setMyWbsConnectionStatus', connectionStatus);
    },
    setMaintenanceMode({ commit }, status) {
      commit('setMaintenanceMode', status);
    }
  },
  mutations: {
	setMyWbsConnectionStatus(state, connectionStatus) {
		state.myWbsConnectionStatus = connectionStatus;
    },
    setMaintenanceMode(state, status) {
      state.isMaintenanceMode = status;
    }
  }
};
